/* signika-300 - latin */
@font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/signika-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/signika-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/signika-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/signika-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/signika-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/signika-v20-latin-300.svg#Signika') format('svg'); /* Legacy iOS */
  }
  
  /* signika-regular - latin */
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/signika-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/signika-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/signika-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/signika-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/signika-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/signika-v20-latin-regular.svg#Signika') format('svg'); /* Legacy iOS */
  }
  
  /* signika-500 - latin */
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/signika-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/signika-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/signika-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/signika-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/signika-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/signika-v20-latin-500.svg#Signika') format('svg'); /* Legacy iOS */
  }
  
  /* signika-600 - latin */
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/signika-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/signika-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/signika-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/signika-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/signika-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/signika-v20-latin-600.svg#Signika') format('svg'); /* Legacy iOS */
  }
  
/** ===================================================
 *  General
 ** =================================================== */
.bg-gray {
    background: #f3efee;
}
// Button
.btn {
    text-transform: uppercase;
}
section {
    position: relative;
    z-index: 1;
}
// Row
.row-flex {
    display: flex;
    flex-wrap: wrap;
}
// Helper
.h-100 {
    height: 100%;
}
/** ===================================================
 *  Typo
 ** =================================================== */
h1, .h1 {
    color: $primary;
}
h2, .h2 {
    color: $primary;
}
h5, .h5 {
    color: $body-color;
}
/** ===================================================
 *  Wave
 ** =================================================== */
 .wave-wrapper-top {
    position: absolute;
    top:-2px;
    z-index: -1;
    width: 100%;
}
.wave-wrapper-bottom {
    position: absolute;
    bottom:-2px;
    z-index: -1;
    width: 100%;
}
/** ===================================================
 *  Header
 ** =================================================== */
header {
    position: fixed;
    width: 100%;
    background: $white;
    z-index: 9999;
}
/** ===================================================
 *  Intro
 ** =================================================== */
#section-intro {
    background: #f3efee;
    padding-bottom: 16rem;

    .list-inline {
        svg {
            margin-right: 2px;
            margin-bottom: 2px;
            width: 1rem;
            color: $gray-700;
        }
    }

    // Kopfhörer
    @include media-breakpoint-up(lg) {
        .intro-image-wrapper {
            position: relative;
            height: 100%;

            .circle {
                background: rgba(255,255,255,0.3);
                width: 100%;
                padding-bottom: 100%;
                border-radius: 50%;
                display: block;
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .img-kopfhoerer {
                position: absolute;
                z-index: 10;
                top: 36%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 68%;
                animation: animate-kopfhoerer 3s 0s ease-in-out infinite alternate;
            }
            .item-shadow {
                position: absolute;
                bottom: 8%;
                left: 50%;
                transform: translateX(-50%);
                width: 43%;
                height: 5%;
                border-radius: 50%;
                box-shadow: 0 30px 5px rgba(0,0,0,0.08);
                z-index: 9;
                animation: animate-shadow 3s 0s ease-in-out infinite alternate;
            }
        }
    }
}
@keyframes animate-kopfhoerer {
    from {
        top:36%;
    }
    to {
        top:32%;
    }
}
@keyframes animate-shadow {
    from {
        opacity: 1;
        width: 43%;
    }
    to {
        opacity: 0.6;
        width: 30%;
    }
}
/** ===================================================
 *  Warum ProBilder
 ** =================================================== */
#warum-probilder {
    
    #rotating-text {
        display: inline-block;
        padding: 0 map-get($spacers, 2);
        color: $black;
        margin-top: map-get($spacers, 2);
        border-bottom:5px solid $primary;
    }
}
/** ===================================================
 *  Beispiele
 ** =================================================== */

/** ===================================================
 *  Ablauf
 ** =================================================== */
#ablauf {

    strong {
        display: block;
        font-weight: 500;
        font-size: $font-size-base * 1.1;

        @include media-breakpoint-up(lg) {
            font-size: $font-size-base * 1.3;
        }
    }
    .icon-process {
        max-width: 90px;
        height: 90px;
        color: $primary;
        margin-bottom: map-get($spacers, 3);

        @include media-breakpoint-up(lg) {
            max-width: 120px;
            height: 120px;
        }

        path {
            fill: $primary;
        }
    }
}

/** ===================================================
 *  Preise
 ** =================================================== */
#preise {

    .price-title {
        font-weight: 600;

        strong {
            font-weight: 600;
            font-size: $font-size-base * 2.4;
            color: $primary;
        }
    }

    .card-body {
        font-size: $font-size-base * 1.2;
        padding-bottom: map-get($spacers, 5);
    }

    ul {
        list-style: none;
        padding-left: map-get($spacers, 3);

        li {
            margin-bottom: map-get($spacers, 2);

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-only(xl) {
                font-size: $font-size-base * 0.95;
            }
        }

        svg {
            margin-right: map-get($spacers, 2);
            width: $font-size-base * 0.7;
            margin-bottom: 2px;
        }
    }

    .price-label {
        background: $primary;
        padding: map-get($spacers, 2) map-get($spacers, 4);
        color: $white;
        display: inline-block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%,50%);
        text-align: center;
        line-height: 1;

        @include media-breakpoint-down(xl) {
            width: 80%;
        }

        .price {
            font-weight: 400;
            font-size: $font-size-base * 2.3;
            margin-bottom: map-get($spacers, 1);

            .prefix {
                font-size: $font-size-base * 1.4;
            }

            @include media-breakpoint-only(xl) {
                font-size: $font-size-base * 1.9;
            }
        }
        .tax-label {
            font-size: $font-size-base * 0.9;
            text-align: center;
            padding-bottom: map-get($spacers, 1);
        }
    }

    .pricing-table {
        td {
            background: $white;
            border-bottom:3px solid $secondary;
            font-weight: 600;

            &:first-child {
                padding-left: map-get($spacers, 4);
            }
            &:last-child {
                padding-right: map-get($spacers, 4);
            }
        }

        svg {
            width: $font-size-base;
            margin-bottom: 3px;
        }
    }
}
/** ===================================================
 *  Footer
 ** =================================================== */
footer {
    
    // Links
    a {
        text-decoration: none;
        color: $body-color;

        &:hover {
            text-decoration: underline;
        }
    }
}
/** ===================================================
 *  360°
 ** =================================================== */
.cloudimage-360 {
    font-size: 0px !important;
}
/** ===================================================
 *  Formular
 ** =================================================== */
label.form-label {
    @include media-breakpoint-down(xl) {
        font-size: $font-size-base;
    }
}
/** ===================================================
 *  Colors
 ** =================================================== */
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$colors: (
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  );

$primary:       #bd172d;
$secondary:     #f3efee;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

/** ===================================================
 *  Spacers
 ** =================================================== */
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6.0,
  8: $spacer * 9,
  9: $spacer * 11,
  10: $spacer * 13,
);

/** ===================================================
 *  Settings
 ** =================================================== */
$variable-prefix:             bs-;
// Body
$body-bg:                   $white;
$body-color:                $gray-900;
// Font
$font-family-sans-serif:     'Signika', sans-serif;
$font-family-base:            var(--#{$variable-prefix}font-sans-serif);
// Font size
$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2.3;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;
// Font weight
$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;
$font-weight-base:            $font-weight-light;
// Headings
$headings-margin-bottom:      $spacer * .5;
$headings-font-family:        null;
$headings-font-style:         null;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              null;
// Lead
$lead-font-size:              1.25rem;
$lead-font-weight:            300;
// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1520px
);
// Navbar
$navbar-nav-link-padding-x:         1rem;
// Buttons
$btn-padding-y:  0.4rem;
$btn-padding-x:  1.5rem;
$btn-font-size: $font-size-base * 1.2;
$btn-font-weight: 500;
// Card
$card-border-width: 0;